import React from 'react'

const CTA = () => {
  return (
    <div className='cta'>
<a href="#portfolio" className='btn'>Portfolio</a>
<a href="#contact" className='btn btn-primary'>Kontakt</a>
    </div>
  )
}

export default CTA