import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'

const About = () => {
  return (
    <section id='about'>
      <h5>Kilka informacji</h5>
      <h2>O mnie</h2>

      <div className="countainer about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="Zdjęcie o mnie" />
          </div>
        </div>
        
        <div className="about__content">
          <p>
            Swoją przygodę z grafiką komputerową rozpocząłem w 2013 roku.
            Od momentu, w którym wykonałem swoje pierwsze zlecenie, stale się rozwijam i
            nie przestaję doskonalić swoich umiejętności.
            </p>

            <p>
            Jestem perfekcjonistą, zawsze dążę do uzyskania najlepszego możliwego efektu
            podczas tworzenia swoich prac.

            Napisz do mnie, a chętnie pomogę Ci stworzyć wpadającą w oko, a jednocześnie przejrzystą identyfikację wizualną.
            Potrzebujesz grafiki na specjalną okazję, witrynę sklepu czy może wizytówkę? Nie ma problemu, dane kontaktowe są na dole strony :)
          </p>
          
        </div>
      </div>
    </section>
  )
}

export default About