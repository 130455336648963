import React from 'react'
import './prices.css'
import {BiCheck} from 'react-icons/bi'

const prices = () => {
  return (
    <section id='prices'>
      <h5>Przykładowe ceny usług</h5>
      <h2>Cennik</h2>
      <div className='container prices__container'>
        <article className="price">

          <ul className='price__list'>
          <table>
            <tr>
              <th>Usługa</th>
              <th>Cena brutto</th>
              <th>Przewidywany czas realizacji</th>
            </tr>

            <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/> Projekt logo (3 wersje graficzne znaku)</td>
              <td data-cell="price">Wycena indywidualna</td>
              <td data-cell="desc">5-8 dni roboczych</td>
            </tr>
            <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Identyfikacja wizualna (Aby uzyskać dokładny opis, napisz) </td>
              <td data-cell="price">Wycena indywidualna</td>
              <td data-cell="desc">10-15 dni roboczych</td>
            </tr>
            <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Projekt strony www</td>
              <td data-cell="price">Wycena indywidualna</td>
              <td data-cell="desc">Do uzgodnienia indywidualnie</td>
              </tr>
            <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Projekt wizytówki (2 projekty)</td>
              <td data-cell="price">100zł</td>
              <td data-cell="desc">1-3 dni robocze</td>
            </tr>
            <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Miniatura ofert dla serwisów sprzedażowych 2szt.</td>
              <td data-cell="price">20zł</td>
              <td data-cell="desc">1-2 dni robocze</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Plakat</td>
              <td data-cell="price">200zł</td>
              <td data-cell="desc">4-8 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Ulotka jednostronna (2 projekty)</td>
              <td data-cell="price">150zł</td>
              <td data-cell="desc">2-3 dni robocze</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Projekt DL</td>
              <td data-cell="price">120zł</td>
              <td data-cell="desc">2-3 dni robocze</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Projekt DL składany</td>
              <td data-cell="price">200zł</td>
              <td data-cell="desc">3-5 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Fotomanipulacja</td>
              <td data-cell="price">Wycena indywidualna</td>
              <td data-cell="desc">5-10 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Wycinanie tła</td>
              <td data-cell="price">od 10zł</td>
              <td data-cell="desc">od ręki lub 1 dzień roboczy</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Opakowanie produktu</td>
              <td data-cell="price">od 350zł</td>
              <td data-cell="desc">2-10 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Menu restauracji</td>
              <td data-cell="price">250-500zł</td>
              <td data-cell="desc">2-6 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Projekt oklejenia auta</td>
              <td data-cell="price">350-900zł</td>
              <td data-cell="desc">5-10 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Przygotowanie do druku</td>
              <td data-cell="price">od 15zł</td>
              <td data-cell="desc">2-5 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Korekta koloru/cieni/świateł</td>
              <td data-cell="price">od 15zł</td>
              <td data-cell="desc">2-5 dni roboczych</td>
              </tr>
              <tr>
              <td data-cell="name"><BiCheck className='service__list-icon'/>Pozostałe</td>
              <td data-cell="price">Do uzgodnienia indywidualnie</td>
              <td data-cell="desc">Do uzgodnienia indywidualnie</td>
              </tr>

            </table>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default prices