import React from 'react'
import './services.css'
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>Co oferuję?</h5>
      <h2>Usługi</h2>

      <div className='container services__container'>
        <article className="service">
          <div className="service__head">
            <h3>Materiały reklamowe</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Opakowania</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Ulotki</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Metki</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Gadżety firmowe</p>
            </li>
          </ul>
        </article>
        {/* END OF WEB DEV */}
        <article className="service">
          <div className="service__head">
            <h3>Identyfikacja wizualna</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Logo</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Wizytówki</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Reklamy tzw. pierwszego kontaktu</p>
            </li>
          </ul>
        </article>
        {/* END OF UI/UX */}
        <article className="service">
          <div className="service__head">
            <h3>Obróbka zdjęć</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Wycinanie/zmiana tła</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Fotomanipulacja</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Korekta kolorów/cieni/świateł</p>
            </li>
            <li>
              <BiCheck className='service__list-icon'/>
              <p>Przygotowanie do druku</p>
            </li>
          </ul>
        </article>
        {/* END OF CONTENT CREATION */}
      </div>
    </section>
  )
}

export default Services