import React from 'react';
import { Link } from 'react-scroll';
import './nav.css';
import { BiHomeAlt } from 'react-icons/bi';
import { BiUser } from 'react-icons/bi';
import { AiOutlinePicture } from 'react-icons/ai';
import { RiServiceLine } from 'react-icons/ri';
import { AiOutlineMessage } from 'react-icons/ai';
import { BiMoneyWithdraw } from 'react-icons/bi';

const Nav = () => {
  return (
    <nav>
      <Link
        to="home"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <BiHomeAlt />
      </Link>
      <Link
        to="about"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <BiUser />
      </Link>
      <Link
        to="services"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <RiServiceLine />
      </Link>
      <Link
        to="portfolio"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <AiOutlinePicture />
      </Link>
      <Link
        to="prices"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <BiMoneyWithdraw />
      </Link>
      <Link
        to="contact"
        activeClass="active"
        spy={true}
        smooth={true}
        offset={-50}
        duration={300}
      >
        <AiOutlineMessage />
      </Link>
    </nav>
  );
};

export default Nav;