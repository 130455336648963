import React from 'react'
import './header.css'
import CTA from './CTA'
import ME from '../../assets/me.png'
import HeaderSocial from './HeaderSocials'

const Header = () => {
  return (
    <header class="header--with-nav">
<div className="container header__container">
  <h5>Witaj, mam na imię</h5>
  <h1>Filip</h1>
  <h5 className="text-light">Jestem grafikiem komputerowym</h5>
<CTA />
<HeaderSocial />

<div className="me">
  <img src={ME} alt="Ja" />
</div>

<a href='#contact' className='scroll__down'>Przewiń w dół</a>
</div>
      </header>
  )
}

export default Header