import React from 'react';
import { Link } from 'react-scroll';
import './footer.css';
import IMG15 from '../../assets/gratyslogo.png';

const Footer = () => {
  return (
    <footer>
      <a href="/" className='footer__logo'><img src={IMG15} alt="Gratys.pl" /></a>

      <ul className='permalinks'>
        <li><Link to="home" smooth={true} duration={500}>Strona główna</Link></li>
        <li><Link to="about" smooth={true} duration={500}>O mnie</Link></li>
        <li><Link to="services" smooth={true} duration={500}>Usługi</Link></li>
        <li><Link to="portfolio" smooth={true} duration={500}>Portfolio</Link></li>
        <li><Link to="prices" smooth={true} duration={500}>Cennik</Link></li>
        <li><Link to="contact" smooth={true} duration={500}>Kontakt</Link></li>
      </ul>
      
      <div className="footer__copyright">
        <small>&copy; Gratys.pl Wszelkie prawa zastrzeżone.</small>
      </div>
    </footer>
  );
}

export default Footer;
